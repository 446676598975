.menu-collapser {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 15px;
  color: #FFF;
  font-size: 14px;
  line-height: 48px;
  background-color: $lw-orange;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'Montserrat',sans-serif;
}
.collapse-button {
  position: absolute;
  top: 50%;
  right: 0;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  padding: 15px 13px 16px 14px;
  transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-left: 1px solid $white;
  transition: background 0.5s ease-out;

  &:hover,
  &:focus {
    color: #FFF;
    background: $lw-light-orange;
    transition: background 0.5s ease-out;
  }

  .icon-bar {
    display: block;
    height: 3px;
    width: 20px;
    margin: 2px 0;
    background-color: #F5F5F5;
    border-radius: 1px;
  }
}

ul.slimmenu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: 'Montserrat',sans-serif;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  &.collapsed {
    li {
      display: block;
      width: 100%;

      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;

      > ul {
        position: static;
        display: none;
      }

      a {
        display: block;
        border-bottom: 1px solid $white;

        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
      }

      .sub-toggle {
        height: 38px;
      }
    }
  }

  li {
    position: relative;
    float: left;
    display: inline-block;
    background-color: $lw-orange;
    border-top: none !important;

    ul {
      margin: 0;
      list-style-type: none;

      li {
        background: $lw-light-orange;
      }
    }

    > ul {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 999;
      display: none;
      width: 100%;

      > li ul {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 999;
        display: none;
        width: 100%;
      }
    }

    a {
      display: block;
      padding: 10px 15px;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      transition: background-color 0.5s ease-out;
      -o-transition: background-color 0.5s ease-out;
      -moz-transition: background-color 0.5s ease-out;
      -webkit-transition: background-color 0.5s ease-out;
      line-height: initial;

      &:hover {
        text-decoration: none;
        background-color: $lw-light-orange;
      }
    }

    .sub-toggle {
      background: $lw-light-orange;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
      width: 48px;
      border-left: 1px solid $white;
      height: 100%;
      text-align: center;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      > i {
        display: inline-block;
        color: $white;
        font-size: 18px;
        vertical-align: middle;
      }
    }
  }

  > li {
    border-left: none;

    &:first-child,
    &.has-submenu + li {
      border-left: 0;
    }
  }
}

.slimmenu .menu-home{
	border-top: 1px solid $white !important;
}