/*LW Wrapper*/

.lw-wrapper{
	padding: 90px 0;
	font-family: 'Montserrat', sans-serif;
	background: $lw-ghost-grey;
}

.lw-wrapper h4{
	text-align: center;
	margin: 0 0 30px;
	text-transform: uppercase;
	color: $lw-black;
}

.lw-wrapper p{
	color: $lw-grey;
}

.lw-wrapper p:last-child{
	margin-bottom: 0;
}

.lw-wrapper strong{
	color: $lw-black;
}

.lw-wrapper ul{
	color: $lw-grey;
}

/*LW Wrapper - Orange*/

.lw-wrapper-orange{
	background-color: $lw-orange;
}

.lw-wrapper-orange h4{
	color: $lw-white;
	margin-bottom: 15px;
}

/*LW Wrapper - Black*/

.lw-wrapper-black{
	background-color: $lw-black;
	color: $lw-light-grey;
}

.lw-wrapper-black h4{
	color: $lw-white;
}

.lw-wrapper-black p{
	color: $lw-light-grey;
}

.lw-wrapper-black strong{
	color: $lw-white;
}

/*LW Wrapper - White*/

.lw-wrapper-white{
	background-color: $lw-white;
}

.lw-wrapper-white h4{
	color: $lw-black;
}

/*LW Page Wrapper*/

.lw-page-wrapper{
	padding-top: 0;
}

/*LW Social Wrapper*/

.lw-wrapper-social img{
	margin: 0 2px;
}

/*LW Locations Wrapper*/

.lw-wrapper-locations{
	padding-bottom: 75px;
}

.lw-wrapper-locations .col-md-4{
	margin: 15px 0;
	text-align: center;
}