.lw-location-row{
	margin-bottom: 30px;
	background: $lw-orange;
}

.lw-location-row:last-child{
	margin-bottom: 0;
	padding-bottom: 0;
	border: none;
}

.lw-location-address{
	background: $lw-orange;
	height: 300px;
	padding: 30px 0 30px 30px;
	position: relative;
}

.lw-location-address h3{
	margin-top: 0;
	color: $lw-white;
}

.lw-location-address p{
	color: $lw-white;
	font-size: 16px;
	line-height: 1.2em;
}

.lw-location-address strong{
	color: $lw-white;
}

.lw-location-directions{
	position: absolute;
	bottom: 0;
	padding: 0 0 30px;
}

.lw-location-directions p{
	font-size: 14px;
	line-height: 1.2em;
}

.lw-location-directions p strong{
	color: $lw-white;
}

.lw-location-map {
	width: 100%;
	height: 300px;
}

/* fixes potential theme css conflict */
.lw-location-map img {
   max-width: inherit !important;
}
