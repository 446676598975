/*Home Carousel*/

.lw-wrapper-home-carousel-slide{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.lw-wrapper-home-carousel-slide img{
	width: 100%;
	height: auto;
	max-height: 730px;
}

/*Owl Carousel*/

.owl-carousel{
	
	.owl-nav{
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		
		button.owl-prev{
			float: left;
			height: 50px;
			width: 50px;
			line-height: 50px;
			font-size: 24px;
			background: $lw-black;
			color: $lw-orange;
			text-align: center;
			transition: all ease 0.5s;
			outline: none;
		}
		
		button.owl-next{
			float: right;
			height: 50px;
			width: 50px;
			line-height: 50px;
			font-size: 24px;
			background: $lw-black;
			color: $lw-orange;
			text-align: center;
			transition: all ease 0.5s;
			outline: none;
		}
		
		button.owl-prev:hover, button.owl-next:hover{
			background: $lw-orange;
			color: $lw-white;
			transition: all ease 0.5s;
		}
	}	
}

/*Home Intro*/

.lw-wrapper-home-intro{
	text-align: center;
	background-image: url(../images/lw-wrapper-triangle.svg);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 30px 15px;
}

.lw-wrapper-home-intro h2{
	color: $lw-white;
	font-size: 48px;
	margin: 0;
}

.lw-wrapper-home-intro h2:after{
	display: block;
    height: 3px;
    background-color: $lw-orange;
    content: " ";
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.lw-wrapper-home-intro p{
	max-width: 800px;
	font-size: 20px;
	margin: 0 auto 1rem;
}

/*Home Grid*/

.lw-wrapper-home-grid{
	padding: 75px 0;
}

/*Whats on*/

.lw-wrapper-home-whats-on{
	padding: 0 0 90px;
	margin: -5px 0 0;
	
	table{
		border: none !important;
		
		tr, th, td{
			border: none !important;
			text-align: center;
		}
		
		tr, th{
			padding: 1rem !important;
		}
		
		td{
			padding: 0.5rem;
		}
		
		td:nth-child(7){
			display: none;
		}
		
		tr.archive-toggler{
			background: $lw-black !important;
			color: $lw-white;
			border-bottom: 1px solid $lw-white !important;
			transition: 0.25s ease-in-out background;
		}
		
		tr.archive-toggler:hover{
			background: #222222 !important;
		}
		
		tr:nth-child(2n+1){
			background: $lw-ghost-grey;
		}
		
		tr:nth-child(2n+2){
			background: $lw-border-grey;
		}
		
	}
}