.lw-header-top{
	padding: 17px 0;
	background: $lw-black;
	font-family: 'Montserrat', sans-serif;
}

.lw-header-top-logo{
	display: block;
	float: left;
	width: 309px;
}

.lw-header-top-nav{
	display: block;
	float: left;
	line-height: 75px;
	width: 100%;
	max-width: 573px;
	text-align: center;
}

.lw-header-top-nav .nav{
	display: block;
}

.lw-header-top-nav ul{
	width: 470px;
	margin: 0 auto
}

.lw-header-top-nav .nav li{
	display: inline-block;
	font-size: 14px;
	padding: 0 8.5px;
}

.lw-header-top-nav .nav li:first-child{
	padding-left: 0;
}

.lw-header-top-nav .nav li:last-child{
	padding-right: 0;
}

.lw-header-top-nav .nav li a{
	color: $lw-white;
}

.lw-header-top-nav .nav li a:hover{
	color: $lw-orange;
	text-decoration: none;
}

.lw-header-top-nav .menu-contact a{
	border-right: 0 !important;
	padding-right: 0 !important;
}

.lw-header-social-and-join{
	width: 228px;
	float: right;
}

.lw-header-top-social-icons{
	display: block;
	float: left;
	width: 106px;
	height: 76px;
	line-height: 76px;
	margin-right: 20px;
}

.lw-header-top-joinnow-button{
	display: block;
	float: left;
	width: 102px;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: none;
}

.lw-header-top-joinnow-button a{
	width: 102px;
	line-height: 48px;
	margin: 13.5px 0 0;
	padding: 0 15px;
	background: $lw-orange;
	border: 0;
	color: $lw-white;
	font-family: 'bebas_neuebold';
	text-transform: uppercase;
	font-size: 24px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	display: block;
	transition: background ease 0.5s;
	outline: none;
	border: none;
}

.lw-header-top-joinnow-button a{
	text-decoration: none;
}

.lw-header-top-joinnow-button a:hover{
	background: #ff9233;
	transition: background ease 0.5s;
}

.lw-header-bottom{
	background: $lw-orange;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: none;
}

.lw-header-bottom .nav li{
	display: block;
	float: left;
	position: relative;
	width: 14.2857142857%;
	text-align: center;
	line-height: 60px;
	border-left: 1px solid $lw-white;
	font-family: 'bebas_neuebold';
	text-transform: uppercase;
	font-size: 24px;
}

.lw-header-bottom .nav li:last-child{
	border-right: 1px solid $lw-white;
}

.lw-header-bottom .nav li a{
	color: $lw-white;
	height: 100%;
	width: 100%;
	display: block;
	transition: background 0.5s ease;
	text-decoration: none;
}

.lw-header-bottom .nav li a:hover{
	background: $lw-light-orange;
	transition: background 0.5s ease;
	text-decoration: none;
}

.lw-header-bottom-tablet{
	background: $lw-orange;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: none;
}

.lw-header-bottom-tablet .nav li{
	display: block;
	float: left;
	position: relative;
	width: 14.2857142857%;
	text-align: center;
	line-height: 60px;
	border-left: 1px solid $lw-white;
	font-family: 'bebas_neuebold';
	text-transform: uppercase;
	font-size: 24px;
}

.lw-header-bottom-tablet .nav li:last-child{
	border-right: 1px solid $lw-white;
}

.lw-header-bottom-tablet .nav li a{
	color: $lw-white;
	height: 100%;
	width: 100%;
	display: block;
	transition: background 0.5s ease;
	text-decoration: none;
}

.lw-header-bottom-tablet .nav li a:hover{
	background: $lw-light-orange;
	transition: background 0.5s ease;
	text-decoration: none;
	color: $lw-white;
	cursor: pointer;
}

/*Sub Menu*/

.sub-menu{
	display: none;
    position: absolute;
    background-color: $lw-orange;
    width: 100%;
    z-index: 9999;
    padding: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    outline: 1px solid $lw-white;
}

.sub-menu .menu-item{
	display: block !important;
	float: none !important;
	width: 100% !important;
	border-left: none !important;
	border-right: none !important;
	border-top: 1px solid $lw-white;
	line-height: 40px !important;
	font-size: 20px !important;
}

.sub-menu .menu-item:first-child{
	border-top: none;
}

.menu-item:hover .sub-menu{
    display: block;
}

.lw-header-bottom-tablet .sub-menu .menu-item{
	font-size: 16px !important;
	line-height: 1rem !important;
}

.lw-header-bottom-tablet .sub-menu .menu-item a{
	padding: 15px 0 !important;
}

/*Responisve Nav*/

.nav-collapse ul {
	background: $lw-orange;
	font-family: 'Montserrat', sans-serif;
}

.nav-collapse li {
	width: 100%;
	border-bottom: 1px solid $lw-white;
}

.nav-collapse li:last-child {
	border-bottom: none;
}

.nav-collapse li a {
	color: $lw-white;
	display: block;
	padding: 7.5px 0 7.5px 15px;
	transition: background ease 0.5s;
}

.nav-collapse li a:hover {
	background: $lw-light-orange;
	cursor: pointer;
	transition: background ease 0.5s;
}

@media screen and (max-width: 767px) {
    
	.tinynav { 
		display: block;
		width: 100%;
		background: url('../images/lw-menu-icon.svg') $lw-orange;
		background-repeat: no-repeat;
		background-size: 20px;
		background-position: right center;
		border: none;
		height: 46px;
		font-size: 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		cursor: pointer;
		color: $lw-white;
		line-height: 1.8rem;
	 }

}

@media screen and (max-width: 767px) {

	.lw-primary-menu-container {
		display: none;
	}

}

.nav-mobile {
	display: none;
}

.nav-mobile ul {
	background: $lw-orange;
	font-family: 'Montserrat', sans-serif;
}

.nav-mobile li {
	width: 100%;
	border: none;
}

.nav-mobile li:last-child {
	border-bottom: none;
}

.nav-mobile li a {
	color: $lw-white;
	display: block;
	padding: 10px 20px;
	transition: background ease 0.5s;
	line-height: 20px;
	border-top: 1px solid $lw-white;
}

.nav-mobile li a:hover {
	background: $lw-light-orange;
	cursor: pointer;
	transition: background ease 0.5s;
}

.nav-mobile li a:last-child {
	border-bottom: none;
}


@media screen and (min-width: 768px) {

	.lw-mobile-menu-container {
		display: none;
	}

}

.lw-mobile-menu-container {

	.nav-toggle {
		position: relative;
		display: block;
		padding: 20px;
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
		line-height: 22px;
		color: #fff;
	}

	.nav-toggle:after {
	  content: "\f0c9";
	  font-family: FontAwesome;
	  font-size: 20px;
	  line-height: 20px;
	  border: none;
	  position: absolute;
	  top: 21px;
	  right: 21px;
	}

	.nav-toggle.active:after {
	  content: "\f00d";
	}

}


/* ------------------------------------------
  MULTIPLE LEVELS
--------------------------------------------- */

.dropdown {
	position: relative;
	
	.main-menu-link {
		margin-right: 60px;
	}

	.menu-item-depth-1 {
		background: $lw-black;
	}

	.sub-menu-link {
		background: $lw-light-orange;
	}

	.sub-menu-link:hover,
	.sub-menu-link:focus {
		background: $lw-orange;
	}

	.dropdown-toggle {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 60px;
		height: 41px;
		background: $lw-light-orange;
		border-left: 1px solid $lw-white;
		padding: 0;
		z-index: 1;
	}

}

.dropdown.active {

	.main-menu-link {
		background: $lw-light-orange;
	}

}

.menu-item-depth-1.active {
	
	.sub-menu-link {
		background: $lw-orange;
	}

}

.dropdown-toggle:after {
  content: "\f067";
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 20px;
  border: none;
  position: relative;
  top: -2px;
  left: 15px;
}

.dropdown.opened {

	.dropdown-toggle {
		height: 42px;
	}

	> .dropdown-toggle:after {
  	content: "\f068";
  	border: none;
  }

}

.dropdown ul {}

.dropdown ul a {}

.dropdown ul ul {}

.dropdown ul ul a {}

@media screen and (min-width: 768px) {

  .js .nav-mobile,
  .js .nav-mobile .dropdown ul {
    max-height: none;
    overflow: visible;
  }

  .js .nav-mobile .dropdown ul {
    display: none;
  }

}