.lw-content-contact{
	padding: 60px 60px 45px 60px;
}

.lw-contact-form .wpcf7{
	margin-top: 30px;
}

label, input, textarea, select{
	width: 100%;
	text-align: left;
}

label{
	margin: 0;
	font-family: 'bebas_neuebold';
	color: #373a3c;
	font-size: 20px;
}

input, textarea, select{
	border: 1px solid $lw-border-grey;
	padding: 10px;
	max-width: 600px;
}

input, select{
	height: 45px;
	color: $lw-grey;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

select{
	background: $lw-white;
}

input[type=submit]{
	background: $lw-orange;
	border: none;
    color: #fff;
    font-family: 'bebas_neuebold';
    font-size: 24px;
    border-radius: 7px;
    padding: 0;
    height: 48px;
    line-height: 48px;
    transition: background ease 0.5s;
    text-align: center;
    margin: 0.75rem 0 0;
    cursor: pointer;
}

input[type=submit]:hover{
	background: $lw-light-orange;
	transition: background ease 0.5s;
}

input[type=submit]:disabled{
	opacity: 0.5;
	background: $lw-orange;
	cursor: not-allowed;
}

div.wpcf7-response-output{
	margin: 2em 0 1em;
}

textarea{
	color: $lw-grey;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;	
}

.lw-contact-key-contacts{
	margin-top: 30px;
}

.wpcf7 input[type=checkbox]{
	width: auto;
	height: auto;
}

.lw-acceptance{
	font-size: 14px;
}

p.lw-acceptance{
	line-height: 1.25;
}

.wpcf7 span.wpcf7-list-item{
	margin: 0;
}