/*Page Hero*/

.lw-page-hero{
	height: 600px;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.lw-page-hero .page-header{
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	padding: 0 30px;
}

.lw-page-hero .page-header h1{
	color: $lw-white;
}

.lw-page-hero .page-header p{
	color: $lw-white;
	max-width: 650px;
	margin: 20px auto 0;
	font-family: 'Montserrat', sans-serif;
}

.lw-page-header-meta{
	font-size: 28px;
	color: $lw-white;
	font-family: 'bebas_neuebold';
}

/*Page Content*/

.lw-content{
	background: $lw-white;
	padding: 30px;
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
	margin-top: -30px;
}

.lw-content-general h3{
	margin-top: 0;
	margin-bottom: 0.75rem;
}

.lw-content-general p+h3{
	margin-top: 2rem;
}

.lw-content-general ul li{
	line-height: 1.4;
	margin-bottom: 5px;
}

.lw-content-general ul li:last-child{
	margin-bottom: 0;
}

.lw-content-general iframe{
	width: 100%;
	height: 1213px;
	overflow: hidden;
}

.lw-content-general iframe .page{
	background: white !important;
}

.lw-content-general iframe .page .page-form{
	margin-top: 0 !important;
}

/*Page Grid*/

.lw-content-grid{
	padding: 15px 30px;
}

.lw-grid-image{
	height: 220px;
	background: $lw-border-grey;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.lw-grid-strip{
	background: $lw-black;
	width: 100%;
}

.lw-grid-strip-link{
	padding: 13.5px;
	float: left;
}

.lw-grid-strip-link h3{
	margin: 0;
	color: $lw-white;
}

.lw-grid-strip-button{
	width: 60px;
	height: 60px;
	background: $lw-orange;
	float: right;
	text-align: center;
	transition: background ease 0.5s;
}

.lw-grid-strip-button:hover{
	background: $lw-light-orange;
	transition: background ease 0.5s;
}

.lw-grid-strip-button .fa{
	color: $lw-white;
	line-height: 60px;
}

/*Page CTA*/

.lw-cta{
	width: 100%;
	text-align: center;
	margin-top: 90px;
	padding: 0 30px;
}

.lw-cta h3{
	margin-bottom: 0.5rem;
	line-height: 1em;
	font-size: 36px;
}

.lw-cta p{
	max-width: 650px;
	margin-left: auto;
	margin-right: auto;
}

/*Posts Nav*/

.qpid-posts-nav{
	text-align: center;
	border-top: 1px solid #eee;
	padding-top: 20px;
	margin-top: 15px;
	margin-bottom: 2.5px;
}

.qpid-posts-nav .active{
	background: $lw-orange;
	border-radius: 7px;
}

.qpid-posts-nav .active a{
	color: $lw-white;
}

.qpid-posts-nav ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

.qpid-posts-nav ul li{
	display: inline;
	padding: 5px 10px;
}

/*Share*/

.lw-share{
	border-top: 1px solid $lw-border-grey;
	margin: 55px 0 -5px;
	padding-top: 25px;
	text-align: center;
}

.lw-share h5{
	margin-bottom: 5px;
}

.lw-content-grid .lw-share{
	margin: 15px 0 -5px;
}

/*Back to*/

.lw-back-to{
	border-top: 1px solid $lw-border-grey;
	margin: 30px 0 -7.5px;
	padding-top: 20px;
	text-align: center;
}

.lw-content-grid .lw-back-to{
	margin: 30px 0 7.5px;
}