/*Fixtures Page*/

.fixtures .lw-page-hero{
	display: none;
}

.fixtures .lw-content{
	margin-top: 0;
}

.fixtures .fl-content:before{
	content:'Fixture';
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px 20px;
	margin-bottom: 28px;
	font-family: 'bebas_neuebold';
	font-size: 30px;
	text-align: center;
}

.fl_table{
	border-color: $lw-border-grey;
}

.team-details th, .team-details td{
	padding: 5px 0;
	text-align: center;
}

.team-details th{
	text-transform: uppercase;
}

.team-details tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.team-details tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.team-details tr:first-child{
	background: $lw-white;
}

.fixtures #displayfixture{
	margin-top: 15px;
	font-size: 14px;
}

.fixtures .fixturedetailtab{
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 0;
	vertical-align: top;
}

.fixtures .fixturedetailtab li{
	display: inline-block;
	width: 14.2857142857%;
	font-size: 14px;
	text-align: center;
	border-right: 1px solid $lw-white;
	height: 42px;
	vertical-align: middle;
	line-height: 1em;
	transition: all 0.5s ease;
	font-family: 'bebas_neuebold';
	font-size: 22px;
}

.fixtures .fixturedetailtab li:last-child{
	border-right: 0;
}

.fixtures .fixturedetailtab li a{
	color: $lw-white;
	background: $lw-orange;
	display: block;
	text-decoration: none;
	height: 100%;
	line-height: 42px;
	transition: all 0.5s ease;
}

.fixturedetailtab li:nth-child(5n+5) a{
	overflow: hidden;
}

.fixtures .fixturedetailtab li a:hover{
	background: $lw-light-orange;
	transition: all 0.5s ease;
}

.fixtures .fixturedetailtab .ui-state-active a{
	background: url(../images/lw-triangle-white.svg) $lw-light-orange;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 10px 5px;
	transition: all 0s ease;
}

.fixtures .fixturedetailtab .ui-state-active a:hover{
	background: url(../images/lw-triangle-white.svg) $lw-light-orange;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 10px 5px;
	transition: all 0s ease;
}

.fixtures #header, .teams #header{
	display: none;
}

.fixtures #footer, .teams #footer{
	display: none;
}

.fixtures #page header, .teams #page header{
	display: none;
}

.fixtures hr, .teams hr{
	display: none;
}

.fl_table{
	width: 100%;
}

.single_team_details h2:before{
	content: 'Key Contact:';
}

/*Team Page*/

.single_team_details_logo, .single_team_details{
	text-align: center;
}

.single_team_details ul{
	list-style: none;
	padding: 0;
	margin: 0 0 30px;
}

/*Teams*/

.teams .lw-page-hero{
	display: none;
}

.teams .lw-content{
	margin-top: 0;
}

.teams .single_team_info:before{
	content:'Team';
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px 20px;
	margin-bottom: 28px;
	font-family: 'bebas_neuebold';
	font-size: 30px;
	text-align: center;
}

.teams .league_details_header:before{
	content:'League';
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px 20px;
	margin-bottom: 28px;
	font-family: 'bebas_neuebold';
	font-size: 30px;
	text-align: center;
}

.teams .league_details_header{
	text-align: center;
}

.teams .division{
	margin-bottom: 30px;
	border: none !important;
}

.teams .division tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.teams .division tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.teams .division tr:first-child{
	background: $lw-white;
}

.teams #tabs ul{
	list-style: none;
	margin: 0 0 15px;
	padding: 0;
	font-size: 0;
}

.teams #tabs ul li{
	width: 50%;
	display: inline-block;
	font-size: 16px;
	height: 42px;
	text-align: center;
	line-height: 42px;
	background: $lw-border-grey;
}

.teams #tabs ul li a{
	display: block;
}

.teams .ui-tabs-active{
	color: $lw-white;
	background: url(../images/lw-triangle-white.svg) $lw-orange !important;
	background-position: center bottom !important;
	background-repeat: no-repeat !important;
	background-size: 10px 5px !important;
}

.teams .ui-tabs-active a{
	color: $lw-white;
}

.teams .division, .teams #tabs .fixtures{
	border: none !important;
}

.fixture_block table{
	margin-bottom: 15px;
	text-align: center;
}

.fixture_block table th{
	text-align: center;
}

.fixture_block table tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.fixture_block table tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.fixture_block table tr:first-child{
	background: $lw-white;
}

.teams #tabs .fixtures .home{
	width: 45% !important;
	text-align: center;
}

.teams #tabs .fixtures .result{
	width: 10% !important;
	text-align: center;
}

.teams #tabs .fixtures .away{
	width: 45% !important;
	text-align: center;
}

.fixture_block table:last-of-type{
	margin-bottom: 0;
}

/*External Competition Page*/

.external-competition .lw-page-hero{
	display: none;
}

.external-competition .lw-content{
	margin-top: 0;
}

.external-competition .league_details_header:before{
	content:'Competition';
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px 20px;
	margin-bottom: 28px;
	font-family: 'bebas_neuebold';
	font-size: 30px;
	text-align: center;
}

.external-competition .league_details_header{
	text-align: center;
}

.external-competition #page #header, .external-competition #page #footer, .external-competition #page hr, .external-competition #page header h1{
	display: none;
} 

.external-competition .division{
	margin-bottom: 30px;
	border: none !important;
}

.external-competition .division tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.external-competition .division tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.external-competition .division tr:first-child{
	background: $lw-white;
}

.external-competition #tabs ul{
	list-style: none;
	margin: 0 0 15px;
	padding: 0;
	font-size: 0;
}

.external-competition #tabs ul li{
	width: 50%;
	display: inline-block;
	font-size: 16px;
	height: 42px;
	text-align: center;
	line-height: 42px;
	background: $lw-border-grey;
}

.external-competition #tabs ul li a{
	display: block;
}

.external-competition .ui-tabs-active{
	color: $lw-white;
	background: url(../images/lw-triangle-white.svg) $lw-orange !important;
	background-position: center bottom !important;
	background-repeat: no-repeat !important;
	background-size: 10px 5px !important;
}

.external-competition .ui-tabs-active a{
	color: $lw-white;
}

.external-competition .division, .teams #tabs .fixtures{
	border: none !important;
}

.fixture_block table{
	margin-bottom: 15px;
	text-align: center;
}

.fixture_block table th{
	text-align: center;
}

.fixture_block table tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.fixture_block table tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.fixture_block table tr:first-child{
	background: $lw-white;
}

.external-competition #tabs .fixtures .home{
	width: 45% !important;
	text-align: center;
}

.external-competition #tabs .fixtures .result{
	width: 10% !important;
	text-align: center;
}

.external-competition #tabs .fixtures .away{
	width: 45% !important;
	text-align: center;
}

.fixture_block table:last-of-type{
	margin-bottom: 0;
}


/*Venues*/

.venues .lw-page-hero{
	display: none;
}

.venues .lw-content{
	margin-top: 0;
}

.venues .location-details:before{
	content:'Venue';
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px 20px;
	margin-bottom: 28px;
	font-family: 'bebas_neuebold';
	font-size: 30px;
	text-align: center;
}

.venues #page #header, .venues #page #footer, .venues #page hr{
	display: none;
}

.venues #page header h1{
	display: none;
}

.venues #page address{
	margin-bottom: 0;
}

.venues .directions h2{
	color: #373a3c;
}

.venues .directions{
	color: $lw-grey;
}