@media(min-width: 1161px){
	.lw-mobile-nav{
		display: none;
	}
}

@media (max-width: 1160px){
	
	.lw-header-top-nav{
		display: none;
	}
	
	.lw-header-social-and-join{
		width: auto;
	}
	
	.lw-header-top-social-icons{
		margin-right: 15px;
	}	
}

@media (min-width: 768px) and (max-width: 1160px)  {
	.lw-nav-toggle{
		width: 48px;
		height: 48px;
		background: $lw-orange;
		display: block;
		outline: none;
		border: none;
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		border-radius: 7px;
		margin: 13.5px 0 0 15px;
		color: $lw-white;
		text-align: center;
		font-size: 24px;
		text-decoration: none;
		float: left;
		line-height: 48px;
		transition: background 0.5s ease;
	}
	
	.lw-nav-toggle:before{
		font-family: FontAwesome;
		content: "\f0c9";
		color: $lw-white;
	}
	
	.lw-nav-toggle.active:before{
		font-family: FontAwesome;
		content: "\f00d";
		color: $lw-white;
	}
	
	.lw-nav-toggle:focus{
		outline: 0;
	}
	
	.lw-nav-toggle:hover{
		background: $lw-light-orange;
		transition: background 0.5s ease;
		cursor: pointer;
	}	
}

@media (min-width: 992px){
	
	.container {
	    max-width: 1140px;
	}	
}

@media (max-width: 991px){
	
	.container {
	    max-width: 100%;
	}	
	
	.sidebar-primary .main{
		flex: 0 0 100%;
	    max-width: 100%;
	}
	
	.sidebar{
		flex: 0 0 100%;
	    max-width: 100%;	
	}
	
	.lw-wrapper .sidebar{
		margin-top: 30px;	
	}
	
	.lw-location-address{
		padding: 30px;
		height: auto;
	}
	
	.lw-location-directions{
		padding: 0;
		position: relative;
	}
	
	.lw-contact-form{
		margin-bottom: 40px;
	}
	
	.lw-contact-form input, .lw-contact-form textarea{
		width: 100%;
		max-width: none;
	}
	
	.lw-header-bottom .nav li{
		font-size: 20px;
	}
}

@media (max-width: 768px){
	.lw-content-single-news, .lw-content-single-events{
		padding: 30px;
	}
	
	.lw-responsive-table{
		overflow-x:auto;
	}
	
}

@media (max-width: 767px){
	.lw-header-bottom{
		height: auto;
	}
}

@media (min-width: 681px){

	.lw-logo-small{
		display: none;
	}	

}

@media (max-width: 680px){
	
	.lw-header-top-logo{
		width: auto;
	}
	
	.lw-logo-large{
		display: none;
	}
	
	.lw-logo-small{
		display: block;
		margin-top: 13.5px;
	}
	
	.lw-team-tabs .nav-item{
		width: 100%;
	}
	
	.lw-team-tabs .nav-item:first-child{
		margin: 0 0 15px;
	}
	
	.lw-team-tabs .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover{
		background: $lw-black;
	}

}

@media (max-width: 480px){
	
	body, p, ul{
		font-size: 14px;
	}
	
	h1{
		font-size: 56px;
		line-height: 48px;
	}
	
	.lw-page-header-meta{
		font-size: 20px;
	}

	.lw-content-general h2{
		font-size: 28px;
	}
	
	.lw-content-general h3{
		font-size: 22px;
	}
	
	.lw-content-general p+h3{
		margin-top: 1.25rem;
	}
	
	.lw-content-single-news p:first-child{
		font-size: 28px;
	}
	
	.lw-col{
		margin: 7.5px 0;
	}
	
	.lw-gallery{
		padding: 0 7.5px;
	}
	
	.lw-gallery-col{
		padding: 0 7.5px;
	}
	
	.lw-content-grid{
		padding: 7.5px 15px;
	}
	
	.lw-grid-image{
		height: 180px;
	}
	
	.lw-content-locations{
		padding: 15px;
	}
	
	.lw-location-address{
		padding: 15px;
	}
	
	.lw-location-address p{
		font-size: 14px;
	}	
	
	.lw-location-directions p{
		font-size: 12px;
	}	
	
	.lw-location-map{
		height: 200px;
	}
	
	.lw-page-hero{
		height: 300px;
	}
	
	.lw-logo-small, .lw-header-top-joinnow-button a, .lw-nav-toggle{
		margin-top: 0;
	}
	
	.lw-header-top-social-icons{
		display: none;
	}
	
	.lw-page-wrapper-team .lw-content{
		padding: 15px;
	}
	
	.lw-content{
		margin-top: -15px;
	}
	
	.lw-location-row{
		margin-bottom: 15px;
	}
	
	.lw-wrapper{
		padding: 60px 0;
	}
	
	.lw-page-wrapper{
		padding-top: 0;
	}
	
	.lw-cta{
		margin-top: 60px;
	}
	
	.lw-page-wrapper-team .lw-back-to{
		margin-bottom: 5px;
	}
	
	.lw-team-carousel, .lw-team-image, .lw-team-info{
		margin-bottom: 7.5px;
	}
	
	.lw-content-grid .lw-share{
		margin: 7.5px 0 -5px;
	}
	
	.lw-content-grid .lw-back-to{
		margin-top: 22.5px;
		margin-bottom: 12.5px;
	}
	
	.lw-wrapper-home-grid{
		padding: 7.5px 0;
	}
	
	.lw-wrapper-home-intro h2{
		font-size: 36px;
	}
	
	.lw-wrapper-home-intro p{
		font-size: 16px;
	}
	
	.lw-wrapper h4{
		font-size: 30px;
	}
	
	.lw-grid-strip-link{
		padding: 8.5px;
	}
	
	.lw-grid-strip-link h3{
		font-size: 28px;
	}
	
	.lw-grid-strip-button{
		width: 48px;
		height: 48px;
	}
	
	.lw-grid-strip-button .fa{
		line-height: 48px;
	}
	
	.lw-cta h3{
		font-size: 30px;
	}
	
	.lw-sidebar h4{
		font-size: 28px !important;
	}
	
	.lw-header-top{
		padding: 12px 0;
	}
	
	.lw-content-contact {
	    padding: 30px;
	}
	
	.lw-contact-form{
		margin-bottom: 30px;
	}
}

@media (min-width: 1025px){
	.lw-header-bottom-tablet{
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 1024px)  {
	.lw-header-bottom{
		display: none;
	}
}

@media (max-width: 767px){
	.lw-header-bottom-tablet{
		display: none;
	}
}