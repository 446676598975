//Fonts

body, p{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6{
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: none;
}

h1{
	font-family: 'bebas_neuebold';
	font-size: 72px;
	line-height: 66px;
	margin: 0;
}

h2{
	font-family: 'bebas_neuebold';
	font-size: 36px;
	line-height: 1em;
	margin: 0 0 1.2rem;
}

h3{
	font-family: 'bebas_neuebold';
	font-size: 30px;
	margin: 0;
	margin: 0 0 1rem;
}

h4{
	font-family: 'bebas_neuebold';
	font-size: 36px;
	margin: 0;
}

h5{
	font-family: 'bebas_neuebold';
	font-size: 24px;
	margin: 0;
}

//Links

a{
	color: $lw-orange;
	transition: color ease 0.5s;
}

a:hover{
	color: $lw-light-orange;
	transition: color ease 0.5s;
	text-decoration: none;
}

a:visited, a:focus{
	text-decoration: none;
}

a img{
	transition: opacity ease 0.5s;
}

a img:hover{
	opacity: 0.8;
	transition: opacity ease 0.5s;
}

.lw-content a[href$=".pdf"]:before{
	font-family: FontAwesome;
	content: '\f016';
	margin-right: 5px;
}

//Classes

.lw-center{
	text-align: center;
}

.lw-col{
	margin: 15px 0;
}