//oEmbed

.entry-content-asset{
	position: relative; 
	padding-bottom: 56.25%;
	overflow: hidden;
	margin: 1.375rem 0 1.5rem;
	max-width: 100%;
	height: auto;
	
	iframe{
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	}
}

//Partners

.lwhc-partner-logo{
	margin: 0 0 24px;
}

.lwhc-partner-logo-s{
	height: 100px;
	max-width: 100%;
	width: auto;
}

.lwhc-partner-logo-l{
	height: auto;
	max-width: 250px;
	width: 100%;	
}