// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;
$lw-orange:				#ff7700;
$lw-light-orange:		#ff9233;
$lw-black:				#000000;
$lw-grey:				#666666;
$lw-light-grey:			#999999;
$lw-border-grey:		#eeeeee;
$lw-ghost-grey:			#f5f5f5;	
$lw-white:				#ffffff;
