/*Wrapper*/

.lw-page-wrapper-team  .lw-content{
	margin-top: 0;
}

/*Content*/

.lw-team-title h1{
	display: block;
	background: $lw-black;
	color: $lw-white;
	padding: 15px;
	font-family: 'bebas_neuebold';
	font-size: 48px;
	text-align: center;
}

.lw-team-image, .lw-team-carousel{
	margin-bottom: 15px;
}

.lw-team-image img{
	width: 100%;
	height: auto;
}

.lw-team-info{
	margin-bottom: 15px;
}

.lw-team-info-wrapper-title{
	margin: 0;
	color: $lw-white;
	display: block;
	padding: 0 15px;
	height: 72px;
	background: url(../images/lw-triangle-orange.svg) $lw-black;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 20px 10px;	
}

.lw-team-info-wrapper-title h4{
	font-size: 28px;
	color: $lw-white;
	line-height: 72px;
	margin: 0;
}

.lw-team-info-wrapper-title h4 i{
	margin-right: 7.5px;
}

.lw-team-info-wrapper a{
	color: $lw-white;
}

.lw-team-info-details{
	background: $lw-orange;
	height: 180px;
	padding: 0 30px;
	text-align: center;
}

.lw-team-info-details-content{
	position: relative;
	top: 50%;
	transform: translateY(-50%);	
}

.lw-team-info-details-content p{
	color: $lw-white;
	margin: 0;
}

.lw-team-info-details-content p a:hover{
	color: $lw-white;
}

.lw-team-info-details-content strong{
	color: $lw-white;
}

.lw-team-info-next-fixture{
	background: $lw-orange;
	height: 180px;
}

.lw-team-info-next-fixture p{
	color: $lw-white;
}

.lw-team-info-next-fixture-top{
	height: 80px;
	border-bottom: 1px solid $lw-white;
	padding: 0 60px;
}

.lw-team-info-next-fixture-bottom{
	height: 80px;
	line-height: 1em;
	text-align: center;
}

.lw-team-info-next-fixture-bottom-content{
	position: relative;
	top: 50%;
	transform: translateY(-50%);	
}

.fixtures_live-minifixture figure, .fixtures_live-minifixture image{
	display: none;
}

.fixtures_live-minifixture-myteam a, .fixtures_live-minifixture-oppoteam a{
	display: block;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.fixtures_live-minifixture-vs{
	font-family: 'bebas_neuebold';
	font-size: 24px;
	line-height: 32px;
}

.lw-team-info-next-fixture li{
	list-style: none;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.lw-team-info-next-fixture h2{
	display: none;
}

.fixtures_live-minifixture{
	text-align: center;
	line-height: 1em;
	color: $lw-white;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.fixtures_live-minifixture p{
	color: $lw-white;
}

.lw-team-info p{
	margin-bottom: 0.5rem;
	line-height: 1rem;
}

.lw-team-info-no-fixture{
	text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*Fixtures Live Table*/

.lw-page-wrapper-team #league-table table{
	border: none;
}

.lw-page-wrapper-team #league-table table tr:nth-child(2n+1){
	background: $lw-ghost-grey;
}

.lw-page-wrapper-team #league-table table tr:nth-child(2n+2){
	background: $lw-border-grey;
}

.lw-page-wrapper-team #league-table table tr:first-child{
	background: $lw-white;
}

.lw-page-wrapper-team #league-table table tr td{
	padding: 5px 0;
}

.lw-page-wrapper-team #league-table table tr td:first-child{
	text-align: center;
}

.lw-page-wrapper-team #league-table table tr td:nth-child(2){
	padding-left: 5px;
}

.lw-page-wrapper-team #league-table table tr td:nth-child(3){
	padding-left: 10px;
}

.lw-page-wrapper-team #league-table table th{
	border: none;
}

.lw-page-wrapper-team #league-table table .tac{
	text-align: center;
}

/*Fixtures and League Table Section*/

.lw-team-fixtures-title{
	margin: 0 0 20px;
	color: $lw-white;
	display: block;
	padding: 0 15px;
	height: 72px;
	background: url(../images/lw-triangle-white.svg) $lw-black;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 20px 10px;	
}

.lw-team-fixtures-title h4{
	font-size: 28px;
	color: $lw-white;
	line-height: 72px;
}

.lw-team-fixtures-title i{
	margin-right: 7.5px;
}

/*Fixtures and League Tabs*/

.lw-team-tabs .nav-tabs{
	border: none;
	margin-bottom: 20px;
}

.lw-team-tabs .nav-item{
	width: 48.5714285715%;
	font-size: 28px;
	font-family: 'bebas_neuebold';
	text-align: center;
	background: $lw-border-grey;
}

.lw-team-tabs .nav-item:first-child{
	margin-right: 2.857142857142857%;
}

.lw-team-tabs .nav-item a{
	color: $lw-orange;
	text-decoration: none;
	display: block;
	padding: 15px;	
}

.lw-team-tabs .nav-item i{
	margin-right: 7.5px;
}

.lw-team-tabs .nav-tabs .nav-item+.nav-item{
	margin-left: 0;
}

.lw-team-tabs .nav-tabs .nav-link{
	border: none;
	border-radius: 0;
}

.lw-team-tabs .lw-tabs{
	margin-bottom: 20px;
	border: none;
}

.lw-team-tabs .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover{
	background: url(../images/lw-triangle-white.svg) $lw-black;
	color: $lw-white;
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: 20px 10px;	
}