.lw-wrapper .sidebar{
	margin-top: -90px;
}

.lw-sidebar{
	background: $lw-white;
	padding: 30px;
	-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
	-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
	box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.10);
}

.lw-sidebar h4{
	margin-top: 0 !important;
	margin-bottom: 1.5rem !important;
	font-size: 30px !important;
	text-align: left !important;
}

.lw-sidebar .nav{
	display: block;
}

.lw-sidebar ul li{
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eeeeee;
}

.lw-sidebar ul li:last-child{
	margin-bottom: 0;
}

.lw-sidebar ul li a{
	transition: opacity 0.5s ease;
	color: $lw-orange;
}

.lw-sidebar ul li a:hover{
	color: $lw-orange;
	opacity: 0.8;
	transition: opacity 0.5s ease;
}

.page-id-254 #menu-members-info{
	display: none !important;
}