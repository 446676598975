/*Events Grid*/

.lw-grid-events{
	background: $lw-black;
	padding: 22.5px 30px 22.5px;
	text-align: center;
	font-size: 14px;
}

.lw-grid-events p{
	color: #cccccc;
	font-size: 14px;
	line-height: 1.2em;
}

.lw-grid-events-date{
	color: $lw-light-grey;
	margin: 0 0 2.5px;	
}

.lw-grid-events-venue{
	color: $lw-light-grey;
	line-height: 1em;
	margin: 10px 0;
	min-height: 28px;
	font-size: 12px;
	text-transform: uppercase;
}

.lw-grid-events-title{
	min-height: 60px;
}

.lw-grid-events-title h3{
	margin: 0;
	color: $lw-white;
	line-height: 1em;
}

.lw-grid-events-excerpt{
	margin-bottom: 15px;
	min-height: 48px;
}

/*Events Content*/

.lw-content-single-events{
	padding: 60px 120px 30px;
}

.lw-content-single-events p:first-child{
	font-size: 36px;
	color: $lw-black;
	font-family: 'bebas_neuebold';
	line-height: 1em;
	margin-bottom: 1.5rem;
}