/*News Grid*/

.lw-content-grid{
	padding: 15px 30px;
}

.lw-grid-image{
	height: 220px;
	background: $lw-border-grey;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.lw-grid-news{
	background: $lw-black;
	padding: 22.5px 30px 22.5px;
	text-align: center;
	font-size: 14px;
}

.lw-grid-news p{
	color: #cccccc;
	font-size: 14px;
	line-height: 1.2em;
}

.lw-grid-news-date{
	color: $lw-light-grey;	
}

.lw-grid-news-title{
	min-height: 60px;
}

.lw-grid-news-title h3{
	margin: 0;
	color: $lw-white;
	margin-bottom: 10px;
	line-height: 1em;
}

.lw-grid-news-excerpt{
	margin-bottom: 15px;
	min-height: 64px;
}

.lw-grid-strip-link{
	padding: 13.5px;
	float: left;
	line-height: 1em;
}

/*News Content*/

.lw-content-single-news{
	padding: 60px 120px 30px;
}

.lw-content-single-news p:first-child{
	font-size: 36px;
	color: $lw-black;
	font-family: 'bebas_neuebold';
	line-height: 1em;
	margin-bottom: 1.5rem;
}