.lw-gallery-col{
	position: relative;
	
	a{
		color: $white;
		display: block;
	}
	
	img{
		width: 100%;
		height: auto;
	}
	
	.lw-gallery-col-overlay{
		background: rgba(0,0,0,0.4);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding: 2rem;
		transition: 0.25s ease-in-out all;
		
		h3{
			margin: 0;
		}
		
		span{
			line-height: 1;
		}
	}
}

.post-type-archive-galleries{
	.lw-content{
		padding: 15px 30px;
	}
}

.tax-teams{
	.lw-content{
		padding: 15px 30px;
	}
}

.single-galleries{
	
	.lw-content{
		padding: 15px 30px 30px;
	}
	
	.lw-gallery{
		
		.lw-gallery-col-overlay{
			font-size: 1.25rem;
			opacity: 0;
		}
		
		.lw-gallery-col:hover .lw-gallery-col-overlay{
			opacity: 1;
		}
		
		span{
		display: block;
		font-family: 'bebas_neuebold';
		line-height: 1;
		padding: 0.75rem 0 0;
		text-align: center;
		}
	}
}